import React, { useEffect } from 'react'
import {Redirect} from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import * as qs from 'query-string';

const DeviceActivateEnterprise = () => {
  useEffect(() => {
    window.location.href = 'surge:///enterprise-license';
  }, []);

  return null;
}

export default DeviceActivateEnterprise
