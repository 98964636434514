import {BrowserRouter, Route, Routes} from 'react-router-dom'
import React from 'react'
import BuyNow from '../BuyNow'
import Home from '../Home'
import Payment from '../Payment'
import PurchaseSuccess from '../PurchaseSuccess'
import Account from '../Account'
import UpdateEmail from '../UpdateEmail'
import Support from '../Support'
import Privacy from '../Legal/Privacy'
import Terms from '../Legal/Terms'
import NoMatch from './NoMatch'
import DeviceActivateEnterprise from '../Go/DeviceActivateEnterprise'
import DeviceActivate from '../Go/DeviceActivate'
import Refund from '../Refund'
import ScrollToTop from '../components/ScrollToTop'

function handleUpdate() {
  let {
    action
  } = this.state.location;

  if (action === 'PUSH') {
    window.scrollTo(0, 0);
  }
}

class Shell extends React.Component {
  render() {
    return (
      <BrowserRouter onUpdate={handleUpdate}>
        <div className="l-container">
        <ScrollToTop />
          <Routes>
            <Route path='*' element={<NoMatch />} />

            <Route path='/' exact element={<Home />} />
            <Route path='/account' element={<Account />} />
            <Route path='/buy_now' element={<BuyNow />} />
            <Route path='/update-email/:token' element={<UpdateEmail />} />
            <Route path='/payment' element={<Payment />} />
            <Route path='/purchase-successful' element={<PurchaseSuccess />} />
            <Route path='/support' element={<Support />} />
            <Route path='/legal/privacy' element={<Privacy />} />
            <Route path='/legal/terms' element={<Terms />} />
            <Route path='/go/activate' element={<DeviceActivate />} />
            <Route path='/go/activate-enterprise' element={<DeviceActivateEnterprise />} />
            <Route path='/refund' element={<Refund />} />
            </Routes>
        </div>
      </BrowserRouter>
    )
  }
}

export default Shell
