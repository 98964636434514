import './index.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import Shell from './Shell'
import * as serviceWorker from './serviceWorker'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Shell />
  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
