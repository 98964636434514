import React from 'react'
import Header from '../Header'
import Footer from '../Footer'

class Terms extends React.Component {
    render() {
        const h3ClassName = 'pt-8 font-medium text-gray-800';

        return <React.Fragment>
            <Header />
            <div className='mb-16 px-8 md:px-32 lg:px-48'>
            <h1 className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">End User License Agreement for Surge Networks Software</h1>
                <div className="mt-16 text-xl leading-8 text-gray-500 space-y-4">
                <p>Please read this End User License Agreement (“EULA”) before You purchase a License Key for the Software and subsequently download and use the Software.</p>

                <p>By purchasing a License Key and/or downloading and using the Software, You agree, without reservation, to be bound by the terms of this EULA. If You do not agree with the terms of this EULA, please do not purchase a License Key and/or download and use the Software.</p>

                <p>If You accept the terms and conditions in this EULA on behalf of a company or other legal entity, You warrant that You have full legal authority to accept the terms and conditions in this EULA on behalf of such company or other legal entity, and to legally bind such company or other legal entity.</p>

                <p>You may not accept this EULA if You are not of legal age to form a binding contract with Surge Networks.</p>

                <h3 className={h3ClassName}>Definitions</h3>

                <p>In this EULA the expressions below shall have the meaning assigned to them in this clause, unless the context requires otherwise:</p>

                <ul>
                    <li><strong>“Activate”</strong> turning the Trial version into the Full version of the same application with the License Key provided by Surge Networks;</li>
                    <li><strong>“Surge Networks”</strong> Surge Networks Inc., 2035 Sunset Lake Road, Suite B-2, in the city of Newark, zip code 19702 and county of New Castle.</li>
                    <li><strong>“Documentation”</strong> the detailed information about the Software, its features and the system requirements as made available on the website of Surge Networks, as amended from time to time;</li>
                    <li><strong>“License Key”</strong> a unique code provided by Surge Networks, which enables You to activate the Trial version or Full version by entering the code into the Software and to subsequently use the Software during the applicable license term;</li>
                    <li><strong>“Full version” or Pro version”</strong> the license for the Software for the term specified on the webpage of the store where You purchase the license, or in any applicable agreement concerning the purchase of the license (as stand-alone product or as part of a subscription) to Use the Software;</li>
                    <li><strong>“Open Source Software”</strong> any software that requires as a condition of use, copying, modification and/or distribution of such software that such software or other software incorporated into, derived from or distributed with such software (a) be disclosed or distributed in source code form, and (b) be licensed for the purpose of making and/or distributing derivative works, and (c) be redistributable at no charge;</li>
                    <li><strong>“Software”</strong> any software application and/or all of the contents of the files and/or other media, including software setup files, licensed to You by Surge Networks, including any Updates;</li>
                    <li><strong>“Trial version”</strong> the license for the Software for the term of 14 days to Use the Software for the sole purpose of testing and evaluating the Software;</li>
                    <li><strong>“Updates”</strong> any modified versions and updates of, and additions to the Software (excluding upgrades of the Software);</li>
                    <li><strong>“Use”</strong> the access, download, install, copy or get benefit from using the Software in accordance with the documentation.</li>
                    <li><strong>“You”</strong> you, the final and ultimate user of the Software or the authorized representative of a company or other legal entity that will be the final and ultimate user of the Software, and the company or other legal entity that will be the final and ultimate user of the Software, if applicable.</li>
                </ul>

                <h3 className={h3ClassName}>General</h3>

                <ol>
                    <li>
                        <p>This EULA applies to any licenses granted to You by Surge Networks for the use of the Software.</p>
                    </li>
                    <li>
                        <p>By purchasing a License Key for the Software and/or downloading and using the Software, You enter into this EULA with Surge Networks.</p>
                    </li>
                    <li>
                        <p>This EULA may be modified from time to time. Surge Networks will notify you of such modifications on its website or otherwise, e.g. by using the email address used for the provision of the License Key. Any modifications to this EULA shall only apply upon acceptance by You.</p>
                    </li>
                </ol>

                <h3 className={h3ClassName}>License</h3>

                <ol>
                    <li>
                        <p>Surge Networks grants You a non-exclusive, non-transferable, limited, revocable license to Use the Software in accordance with this EULA. Surge Networks reserves all rights not expressly granted to You.</p>
                    </li>
                    <li>
                        <p>Surge Networks is and remains the owner of any intellectual property rights with respect to the Software. You shall not acquire any ownership to the Software as result of Your purchase of the License Key or Your Use of the Software.</p>
                    </li>
                </ol>

                <h3 className={h3ClassName}>Permitted use and restrictions</h3>

                <ol>
                    <li>
                        <p>In order to be able to install the Software and receive Updates and upgrades, Your computer shall have access to the Internet and shall meet the system requirements described in the Documentation that can be found on the webpage: https://www.nssurge.com/.</p>
                    </li>
                    <li>
                        <p>You may Use the Software on one device only, unless You have purchased (a) a volume license or (b) more than one Full version or Trial version. In the event You have purchased a volume license or more than one Full version or Trial version, the number of computers You may Use the Software on shall not exceed the number of the Full version or Trial version purchased. If You exceed the limit, Surge Networks may block the License Key.</p>
                    </li>
                    <li>
                        <p>You shall Use the Full version or Trial version in accordance with applicable laws and shall not:</p>

                        <ol>
                            <li>Use the Trial version on any system where the Trial version was previously Used and expired;</li>
                            <li>rent, lease, lend, sell, redistribute, sublicense or otherwise commercially exploit the License Key, the Full version or the Trial version;</li>
                            <li>assign the License Key, the Full version or the Trial version without prior written approval of Surge Networks;</li>
                            <li>Use the Full version or Trial version for any unlawful or illegal activity, or to facilitate an unlawful or illegal activity;</li>
                            <li>delete or alter any disclaimers, warnings, copyright or other proprietary notices accompanying the Software; or</li>
                            <li>copy (except as otherwise provided in this EULA), adapt, translate, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify or create derivative works of the Software and the License Key or any parts thereof, except to the extent permitted by applicable law.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Surge Networks may modify the Software at any time at its sole discretion and without notice to You, for example to comply with applicable law or a court order, to avoid a third party infringement claim or to provide Updates and upgrades.</p>
                    </li>
                    <li>
                        <p>Certain components of the Software are Open Source Software and licensed under the terms of the applicable license(s) of the Open Source Software. You shall adhere to these terms and conditions.</p>
                    </li>
                </ol>

                <h3 className={h3ClassName}>Maintenance and support</h3>

                <ol>
                    <li>
                        <p>Surge Networks will notify You of any available Updates and upgrades.</p>
                    </li>
                    <li>
                        <p>You will be entitled to receive Updates free of charge during a period of twelve (12) months upon purchasing the License Key for the Full/Pro version. In order to receive Updates after this twelve months’ period, You will need to renew your Feature Subscription.</p>
                    </li>
                    <li>
                        <p>You may need to pay an additional fee in order to be able to unlock new features.</p>
                    </li>
                    <li>
                        <p>Any maintenance and support provided by Surge Networks, including the provision of Updates and upgrades, will be provided in an adequate way on an “as is” basis without any warranty, as soon as reasonably practicable, subject to availability of personnel.</p>
                    </li>
                </ol>

                <h3 className={h3ClassName}>Use of data</h3>

                <ol>
                    <li>
                        <p>Surge Networks and its subsidiaries may periodically collect and use technical and related data concerning the Software You have licensed, including about the version number of the Software You have installed and about the system You have installed the Software on. Surge Networks will use such data to facilitate maintenance and support with respect to the Software, to improve its products and to provide further services or technologies to You.</p>
                    </li>
                    <li>
                        <p>Surge Networks may process personal data with respect to You, if and to the extent necessary to provide the License Key, to provide maintenance and support to You with respect to the Software and to comply with its obligations under this EULA. To the extent Surge Networks will process personal data, it will comply with its obligations under applicable data protection law. Please see <a href="/privacy">our privacy statement</a> for more details on the processing of Your personal data that Surge Networks has collected and received through its website.</p>
                    </li>
                </ol>

                <h3 className={h3ClassName}>Disclaimer</h3>

                <ol>
                    <li>
                        <p>You Use the Software at Your own risk and the entire risk as to satisfactory quality, performance and accuracy is with You.</p>
                    </li>
                    <li>
                        <p>The Software and accompanying documentation are provided on an “as is” and “as available” basis without warranty - express or implied- of any kind, and Surge Networks specifically disclaims the warranty of fitness for a particular purpose. No oral or written advice given by Surge Networks, its dealers, distributors, agents or employees shall create a warranty or in any way increase the scope of this warranty and You may not rely upon such information or advice.</p>
                    </li>
                </ol>

                <h3 className={h3ClassName}>Liability limitation</h3>

                <ol>
                    <li>
                        <p>The liability of Surge Networks and any third party that has been involved in the creation, production, or delivery of the Software for all damages arising out of or in any way relating to the License version, the Trial version, the License Key, the Software and/or this EULA shall in no event exceed the total amount of $99.</p>
                    </li>
                    <li>
                        <p>Surge Networks and any third party that has been involved in the creation, production, or delivery of the Software are under no circumstances liable for consequential or indirect damages (including damage for loss of profit, business interruption, loss of data, and the like arising out of the use or inability to use the Software).</p>
                    </li>
                    <li>
                        <p>The limitations of liability referred to in clause 7.1 and 7.2 shall not apply in the event that: (i) any negligence on the part of Surge Networks leads to death or physical injury or (ii) damages arise from the intent, willful misconduct or gross negligence of Surge Networks or the management of Surge Networks.</p>
                    </li>
                </ol>

                <h3 className={h3ClassName}>Restrictions</h3>
                <ol>
                <li>
                    <p>You may not, or permit others to, (i) de-compile, reverse engineer, reverse compile, modify or attempt to derive any source code obtained under this Agreement; (ii) create any derivative works of the Software; (iii) remove, alter, obscure or deface any copyright notice, trademark, logo, or other notice identifying Surge Networks or its licensors; (iv) make any copies of the Software except for an archival copy for back-up purposes; (v) attempt to "hack" or "crack" or otherwise override any activation key or other mechanism that may be provided or necessary to access the Software; or (vi) use the Software in any manner that violates any law, this Agreement or the rights of Surge Networks or any third party.</p>
                </li>
                <li>
                    <p>If Surge Networks found You attempt to "hack" or "crack" or otherwise override any activation key or other mechanism, Surge Networks can revoke Your license without prior notice and no refund will be issued. And Surge Networks have the right to use Your data for subsequent legal actions.</p>
                </li>
                </ol>

                <h3 className={h3ClassName}>Termination</h3>

                <ol>
                    <li>
                        <p>This EULA will continue to be in force until the expiry or termination of the Full version or Trial version.</p>
                    </li>
                    <li>
                        <p>The Full version or Trial version will terminate automatically at the end of the applicable license term, unless renewed or Activated prior to the end of the applicable license term.</p>
                    </li>
                    <li>
                        <p>Surge Networks is entitled to terminate the Full version or Trial version by blocking the License Key during the license term with immediate effect and without prior notice in the event You fail to comply with the terms in this EULA, in the event You fail to pay Your (subscription) fee for the License Key within the applicable payment term, or in the event Surge Networks is required to do so by law or an order of an applicable court.</p>
                    </li>
                    <li>
                        <p>Upon termination of the Full version or Trial version, You shall cease all use of the Software and shall destroy all copies thereof.</p>
                    </li>
                    <li>
                        <p>You acknowledge that the provisions of this EULA, which by their nature are intended to survive termination, will remain in effect after termination of this EULA.</p>
                    </li>
                </ol>

                <h3 className={h3ClassName}>Governing law and disputes</h3>

                <ol>
                    <li>
                        <p>This EULA shall be governed by and construed in accordance with the laws of the United States, excluding its conflicts of law rules.</p>
                    </li>
                </ol>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    }
}

export default Terms
