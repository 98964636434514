import { Dialog } from "@headlessui/react";
import * as qs from "query-string";
import React from "react";
import { get, post } from "superagent";
import Footer from "../Footer";
import Header from "../Header";
import {
  API_BASE,
  buildQuery,
  checkEmail,
  isChineseLanguage,
  showAPIErrorAlert,
} from "../util";
import { stripeForRenewFUS } from "../util/stripe";
import "./index.css";
import TestFlight from "./TestFlight";

import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/20/solid";
import withRouter from "../util/withRouter";

const {Stripe} = window

class Account extends React.Component {
  state = {
    devices: null,
    type: "surge-mac-3",
    email: "",
    key: "",
    loading: false,
    showTestFlightDialog: false,
    response: null,
  };

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    let state = {};

    if (query.email) state.email = query.email;
    if (query.key) state.key = query.key;
    if (query.type) state.type = query.type;
    if (query.autoLogin) {
      this.setState(state, () => {
        this.login();
      });
    } else {
      this.setState(state);
    }
  }

  reboundCheck = (email) => {
    if (window.Rebound) {
      window.Rebound.check({
        publicToken: "1xdybdwemym4qrz5mw2dhh",
        email: email,
        actionLabel: "Contact Support",
        appearance: "alert",
        title: "Email Delivery Failed",
        description:
          "An email recently sent to [[email]] has bounced. Error: [[reason]]<br />Please contact support@nssurge.com to update your email or reactivate the address.",
        actionUrl: "mailto:support@nssurge.com",
      });
    } else {
      console.log("Rebound script is not loaded");
    }
  };

  changeEmail = () => {
    alert(
      `The license is not allowed to transfer to other individuals. You may only change the email address to another one which you own. The purchaser can always retrieve the license with the original email address and the receipt.`
    );
    const newEmail = prompt("Enter new email");

    if (newEmail) {
      const email = checkEmail(this.state.email);
      if (!email) {
        return false;
      }

      const key = this.state.key.trim();
      if (!key) {
        return false;
      }

      const type = this.state.type;

      this.setState({ loading: true });

      post(API_BASE + "/account/update-email")
        .send({
          email,
          newEmail,
          type,
          key,
        })
        .end((err, res) => {
          this.setState({ loading: false });

          if (err || res.body.code < 0) {
            alert(err ? err.message : res.body.message);
            return;
          }
          alert(
            `A verification mail was sent to your current email address ${email}. Please click the link in the mail to complete.`
          );
        });
    }
  };

  /**
   * Trigger when the "forgot key" button is click
   *
   * @memberof Account
   */
  forget = (e) => {
    e.preventDefault();
    const email = checkEmail(this.state.email);
    if (!email) {
      return false;
    }

    if (
      window.confirm(
        `The license key will be resent to ${email}. Are you sure you entered your email correctly?`
      )
    ) {
      this.setState({ loading: true });

      this.reboundCheck(email);

      post(API_BASE + "/account/retrieve")
        .send({ email, type: this.state.type })
        .end((err, res) => {
          this.setState({ loading: false });

          if (err) {
            showAPIErrorAlert(err);
            return;
          }
          const { body } = res;
          if (body.message) {
            alert(body.message);
          } else {
            alert(
              "The license key has been resent. Please check your email inbox."
            );
          }
        });
    } else {
      return false;
    }
  };

  login = () => {
    const email = checkEmail(this.state.email);
    if (!email) {
      return false;
    }

    const key = this.state.key.trim();
    if (!key) {
      alert("Please enter your key.");
      return false;
    }

    const type = this.state.type;

    this.setState({ loading: true });

    this.reboundCheck(email);

    get(API_BASE + "/account/management")
      .query({
        email: email,
        key: key,
        type: type,
      })
      .end((err, res) => {
        this.setState({ loading: false });

        if (err) {
          showAPIErrorAlert(err);
          return;
        }
        const { body } = res;

        console.log(body);
        if (body.devices) {
          this.setState({
            devices: body.devices,
            license: body.license,
            response: body,
          });
        } else {
          alert(body.message);
        }
      });
    return false;
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  typeDidChange = (event) => {
    this.setState({ type: event.target.value });
  };

  isLoading = () => {
    return this.state.loading;
  };

  renderForm() {
    return (
      <div>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-lg">
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              License Management
            </h2>
          </div>

          <div className="mt-8 px-8 mx-auto w-full max-w-md sm:max-w-xl">
            <div className="bg-gray-50 py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form
                className="space-y-6"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.login();
                }}
              >
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    License Type
                  </label>
                  <div className="mt-2">
                    <div className="space-y-2 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                      <div key="surge-mac-3">
                      <label className="flex items-center text-sm font-medium text-gray-700">

                        <input
                          value="surge-mac-3"
                          type="radio"
                          name="type"
                          checked={this.state.type === "surge-mac-3"}
                          onChange={this.typeDidChange}
                          className="mr-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                          Surge Mac v3/v4/v5
                        </label>
                      </div>
                      <div key="surge-ios">
                      <label className="text-sm font-medium text-gray-700 flex items-center">

                        <input
                          value="surge-ios"
                          type="radio"
                          name="type"
                          checked={this.state.type === "surge-ios"}
                          onChange={this.typeDidChange}
                          className="mr-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                          Surge iOS
                        </label>
                      </div>

                      <div key="surge-mac">
                      <label className="flex items-center text-sm font-medium text-gray-700">
                        <input
                          value="surge-mac"
                          type="radio"
                          name="type"
                          checked={this.state.type === "surge-mac"}
                          onChange={this.typeDidChange}
                          className="mr-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                          Surge Mac v2
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    License Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      onChange={this.handleInputChange}
                      value={this.state.email}
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    License Key
                  </label>
                  <div className="mt-1">
                    <input
                      id="key"
                      name="key"
                      type="text"
                      onChange={this.handleInputChange}
                      value={this.state.key}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-center">
                  <div className="text-sm">
                    <button
                      type="button"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                      onClick={this.forget}
                    >
                      Forgot your key?
                    </button>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    disabled={this.isLoading()}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  resetDevice = (e) => {
    const c = window.confirm(
      `Are you sure to reset your license? All your device will be deactivated and your license key will be re-generated.`
    );
    if (!c) return;

    this.setState({ loading: true });

    const { email, key, type } = this.state;
    post(API_BASE + "/account/reset")
      .send({ email, key, type })
      .end((err, res) => {
        this.setState({ loading: false });

        if (err) {
          showAPIErrorAlert(err);
          return;
        }
        const { body } = res;
        if (body.error) {
          alert(body.message);
        } else {
          alert(body.message);
          window.location.reload();
        }
      });
  };

  showUpgradeOption = (e) => {
    this.props.history.push(
      `/buy_now?${buildQuery({ upgrade_email: this.state.email })}`
    );
  };

  showTestFlight = () => {
    this.setState({ showTestFlightDialog: true });
  };

  renewFUS = (e) => {
    stripeForRenewFUS(
      this.state.response.stripeKeyPublishable,
      this.state.response.stripeFUSPlan,
      this.state.email
    );
    this.setState({ loading: true });
  };

  renewFUSAlipay = () => {
    this.setState({ loading: true });


    post(API_BASE + '/payment/stripe-alipay-intent').send({
      sku: this.state.response.fusSKU,
      email: this.state.email,
    }).end((err, res) => {
      if (err) {
        alert('Request failed. Please try again. Error ' + err.message)
        this.setState({loading: false})
        return
      }
      if (res.body.message) {
        alert(res.body.message)
        this.setState({loading: false})
        return
      }

      const stripe = Stripe(res.body.keyPublishable);

      stripe.confirmAlipayPayment(res.body.clientSecret,
        {
          // Return URL where the customer should be redirected after the authorization.
          return_url: `https://nssurge.com/purchase-successful?email=${this.state.email}`
        }
      ).then(function (result) {
        if (result.error) {
          console.log(result.error);
          alert(result.error.message)
          this.setState({loading: false})
          return
        }
      });
    }
    )
  };

  disableAutomaticRenewal = (e) => {
    const c = window.confirm(
      `Are you sure to disable automatic renewal? After disabling you can only renew manually after the expiration.`
    );
    if (!c) return;

    this.setState({ loading: true });

    const { email, key, type } = this.state;
    post(API_BASE + "/account/cancel-subscription")
      .send({ email, key, type })
      .end((err, res) => {
        this.setState({ loading: false });

        if (err) {
          showAPIErrorAlert(err);
          return;
        }
        const { body } = res;
        if (body.error) {
          alert(body.message);
        } else {
          alert(body.message);
          this.login();
        }
      });
  };

  stripePortal = (e) => {
    this.setState({ loading: true });

    const { email, key, type } = this.state;
    post(API_BASE + "/account/stripe-portal")
      .send({ email, key, type })
      .end((err, res) => {
        this.setState({ loading: false });

        if (err) {
          showAPIErrorAlert(err);
          return;
        }

        window.location.href = res.body.url;
      });
  };

  renderAccount() {
    const fusDate = new Date(this.state.response.fusDate);
    const fusExpired = fusDate < new Date();

    let automaticRenewal = "Disabled";
    let renewalText = null;

    if (this.state.response.fusSource === "IAP") {
      automaticRenewal = "In-App Purchase";
      renewalText = "Please manage your subscription with your iDevice.";
    } else if (this.state.response.fusSource === "STRIPE-ACTIVE") {
      automaticRenewal = "Credit Card";
      renewalText = `You will be billed at ${fusDate.toLocaleDateString()} for the next year.`;
    } else if (this.state.response.allowsFUSReactivate) {
      renewalText =
        "You may reactivate the automatic renewal before the billing cycle ends. If you want to change another payment method, please wait until the expiration date.";
    } else {
      if (fusExpired) {
        renewalText =
          "Renew your feature subscription now to unlock all the new features.";
      } else {
        renewalText =
          "You may renew your subscription 3 days before the expiration date.";
      }
    }

    return (
      <section>
        <div className="bg-white shadow sm:rounded-lg max-w-4xl mx-auto mt-20">
          <div className="px-4 py-5 sm:px-6">
            <h2
              id="applicant-information-title"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              License Information
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              You may manage your license here.
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">License</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {this.state.license}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Licensed To
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {this.state.email}
                </dd>
              </div>
              {this.state.type === "surge-ios" && (
                <React.Fragment>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Feature Subscription
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <p>
                        {fusExpired ? "Expired" : "Expires"} on{" "}
                        {fusDate.toLocaleDateString()}{" "}
                        <span
                          style={{
                            color: fusExpired ? "#D0021B" : "#7ED321",
                            fontWeight: "bold",
                          }}
                        >
                          {fusExpired ? "(Expired)" : "(Active)"}
                        </span>
                      </p>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Automatic Renewal
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {automaticRenewal}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Subscription Management
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {renewalText}
                    </dd>
                    <dd className="mt-2 text-sm text-gray-900 space-x-3">
                      {this.state.response.allowsFUSManagement && (
                        <button
                          type="button"
                          onClick={this.stripePortal}
                          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Manage Billing Information
                        </button>
                      )}
                      {this.state.response.allowsFUSRenew && (
                        <button
                          type="button"
                          onClick={this.renewFUS}
                          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Renew with Credit Card
                        </button>
                      )}
                      {this.state.response.allowsFUSRenew && (
                        <button
                          type="button"
                          onClick={this.renewFUSAlipay}
                          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Renew with Alipay
                        </button>
                      )}
                    </dd>
                  </div>
                </React.Fragment>
              )}

              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Licensed Devices
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {this.state.devices.length > 0 ? (
                    <ul
                      className="divide-y divide-gray-200 rounded-md border border-gray-200"
                    >
                      {this.state.devices.map((device) => (
                        <li
                          key={device.deviceName}
                          className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                        >
                          <div className="flex w-0 flex-1 items-center">
                            {this.state.type === "surge-ios" ? (
                              <DevicePhoneMobileIcon
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ComputerDesktopIcon
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                            <span className="ml-2 w-0 flex-1 truncate">
                              {device.deviceName}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "No Devices"
                  )}
                </dd>
              </div>

              <div className="sm:col-span-2 flex justify-end space-x-3">
                {this.state.type === "surge-ios" ? (
                  <React.Fragment>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                      onClick={this.showTestFlight}
                    >
                      Apply TestFlight
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                  onClick={this.changeEmail}
                >
                  Change Email
                </button>

                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                  onClick={this.resetDevice}
                >
                  Reset License
                </button>
              </div>
            </dl>
          </div>
          <div></div>
        </div>
      </section>
    );
  }

  render() {
    let faqs = [];

    if (!this.state.license) {
      faqs = [
        {
          question: "How to retrieve the license key?",
          answer:
            'In case you forget your license key, you can fill your email address above and click "Forgot your key" to retrieve. The license key will be resent to your mailbox. If you cannot access to your mailbox, please contact support@nssurge.com.',
        },
        {
          question: "How to transfer license to other devices?",
          answer:
            "If you want to utilize Surge on another device, you can always switch devices by deactivating one of the devices in Surge Mac license window so that the other device can use the slot.",
        },
      ];
    } else {
      if (this.state.type === "surge-ios") {
        faqs.push({
          question: "What is Feature Subscription?",
          answer:
            "Surge iOS uses a subscription update system, which gives you all new feature updates for free for one year after purchase. After the expiration date, you will need to renew your subscription to unlock subsequent new features. Unlocked features are available for lifetime, and maintenance updates are available for lifetime.",
          url: isChineseLanguage()
            ? "https://nssurge.zendesk.com/hc/zh-cn/articles/360025271874"
            : "https://nssurge-english.zendesk.com/hc/en-us/articles/360025426913",
        });
      }

      faqs.push({
        question: 'What does "Reset License" mean?',
        answer:
          "If you forget to deactivate the license and you lost control of the activated device. You may reset your license to deactivate all devices. But you can only perform reset every 7 days.",
      });
    }

    return (
      <React.Fragment>
        <Header />
        <div>
          <div>
            {this.state.license ? this.renderAccount() : this.renderForm()}
          </div>

          <div className="mx-auto mb-20 mt-10 max-w-6xl pt-16 px-4 sm:px-6 lg:pt-20 lg:px-8">
            <div className="lg:grid lg:grid-cols-4 lg:gap-8">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Frequently asked questions
                </h2>
              </div>
              <div className="mt-12 lg:col-span-3 lg:mt-0">
                <dl className="space-y-12">
                  {faqs.map((faq, i) => (
                    <div key={i}>
                      <dt className="text-lg font-medium leading-6 text-gray-900">
                        {faq.question}
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        {faq.answer}
                      </dd>
                      {faq.url && (
                        <dd className="mt-2">
                          <a
                            href={faq.url}
                            target="_blank"
                            rel="noreferrer"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Read More…
                          </a>
                        </dd>
                      )}
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        <Dialog
          open={this.state.showTestFlightDialog}
          onClose={() => {
            this.setState({ showTestFlightDialog: false });
          }}
        >
          <TestFlight
            token={{ email: this.state.email, key: this.state.key }}
            onClose={() => {
              this.setState({ showTestFlightDialog: false });
            }}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withRouter(Account);
