import "./index.css";
import React from "react";
import { NavLink, Link } from "react-router-dom";

export default function Header() {
  const navLinkBase = "text-center text-base font-medium hover:text-gray-900"
  return (
    <header>
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8" aria-label="Top">
        <div className="flex w-full flex-col items-center justify-between py-4 md:py-16 md:flex-row space-y-4 md:space-y-0">
          <div className="md:flex items-center hidden">
            <Link to="/">
              <span className="sr-only">Surge</span>
              <img srcSet={require("./logo.png") + " 2x"} className='w-full' alt="Logo" />
            </Link>
          </div>
          <div className="flex items-center md:ml-12 flex-row">
            <div className="md:ml-10 md:space-x-8 md:block space-x-3 flex items-center">
              <div className="md:hidden">
                <Link to="/">
                  <img srcSet={require("./logo-small.png") + " 2x"} className='w-[32px]' alt="Logo" />
                </Link>
              </div>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? (navLinkBase + " md:inline" + " text-gray-900") : (navLinkBase + " md:inline" + " text-gray-500")
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/support"
                className={({ isActive }) =>
                  isActive ? (navLinkBase + " md:inline" + " text-gray-900") : (navLinkBase + " md:inline" + " text-gray-500")
                }
              >
                Support
              </NavLink>
              <NavLink
                to="/account"
                className={({ isActive }) =>
                  isActive ? (navLinkBase + " inline lg:hidden" + " text-gray-900") : (navLinkBase + " inline lg:hidden" + " text-gray-500")
                }
              >
                License
              </NavLink>

              <NavLink
                to="/account"
                className={({ isActive }) =>
                  isActive ? (navLinkBase + " hidden lg:inline" + " text-gray-900") : (navLinkBase + " hidden lg:inline" + " text-gray-500")
                }
              >
                License Management
              </NavLink>

            </div>
            <Link
              to="/buy_now"
              className="ml-4 md:ml-8 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-2 md:px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
            >
              Buy Now
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
}
