import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { isChineseLanguage } from '../util'

import { BuildingLibraryIcon, BookOpenIcon, ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'


function Support() {
  const helpCenterURL = isChineseLanguage() ? "https://kb.nssurge.com/surge-knowledge-base/v/cn/" : "https://kb.nssurge.com/surge-knowledge-base/v/en/"
  const bookURL = isChineseLanguage() ? "https://manual.nssurge.com/book/understanding-surge/cn/" : "https://manual.nssurge.com/book/understanding-surge/en/"

  return <React.Fragment>
    <Header />
    <div className="bg-white">
      {/* Header */}
      <div className="relative bg-gray-800 pb-32">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
            alt=""
          />
          <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">Support Center</h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300">
            {/* Subtitle */}
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="relative z-10 mx-auto -mt-32 max-w-7xl px-4 pb-32 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          <div className="flex flex-col rounded-2xl bg-white shadow-xl">
            <div className="relative flex-1 px-6 pt-16 pb-8 md:px-8">
              <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-indigo-600 p-5 shadow-lg">
                <BuildingLibraryIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">Knowledge Base</h3>
              <p className="mt-4 text-base text-gray-500">The knowledge base contains a wealth of guidance content and answers to frequently asked questions.</p>
            </div>
            <div className="rounded-bl-2xl rounded-br-2xl bg-gray-50 p-6 md:px-8">
              <a href={helpCenterURL} className="text-base font-medium text-indigo-700 hover:text-indigo-600">
                Knowledge Base<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
          <div className="flex flex-col rounded-2xl bg-white shadow-xl">
            <div className="relative flex-1 px-6 pt-16 pb-8 md:px-8">
              <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-indigo-600 p-5 shadow-lg">
                <BookOpenIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">Online Manual</h3>
              <p className="mt-4 text-base text-gray-500">The online manual contains guidelines on how to write Surge profiles and how to utilize various advanced features.</p>
            </div>
            <div className="rounded-bl-2xl rounded-br-2xl bg-gray-50 p-6 md:px-8">
              <a href="https://manual.nssurge.com/" className="text-base font-medium text-indigo-700 hover:text-indigo-600">
                Online Manual<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
          <div className="flex flex-col rounded-2xl bg-white shadow-xl">
            <div className="relative flex-1 px-6 pt-16 pb-8 md:px-8">
              <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-indigo-600 p-5 shadow-lg">
                <ChatBubbleLeftEllipsisIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">Community</h3>
              <p className="mt-4 text-base text-gray-500">You can discuss with other users in the community. We will also post some technical notes here.</p>
            </div>
            <div className="rounded-bl-2xl rounded-br-2xl bg-gray-50 p-6 md:px-8">
              <a href="https://community.nssurge.com/t/official" className="text-base font-medium text-indigo-700 hover:text-indigo-600">
                Community<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>

        </div>

        <div className="relative mx-auto mt-16 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-md lg:max-w-5xl">
            <div className="flex flex-col gap-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 lg:flex-row lg:items-center lg:gap-8">
              <div className="lg:min-w-0 lg:flex-1">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Understanding Surge</h3>
                <div className="mt-2 text-base leading-7 text-gray-600">
                  An official guide book to help you understand Surge.
                </div>
              </div>
              <div>
                <a
                  href={bookURL}
                  className="inline-block rounded-lg bg-indigo-50 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-indigo-700 hover:bg-indigo-100"
                >
                  Read <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="relative mx-auto mt-16 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-md lg:max-w-5xl">
            <div className="flex flex-col gap-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 lg:flex-row lg:items-center lg:gap-8">
              <div className="lg:min-w-0 lg:flex-1">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Legacy Versions</h3>
                <div className="mt-2 text-base leading-7 text-gray-600">
                  You may check the release notes and download the history versions here. Surge Mac v2/v3/v4 are also included.
                </div>
              </div>
              <div>
                <a
                  href="/go/en/mac-legacy-versions"
                  className="inline-block rounded-lg bg-indigo-50 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-indigo-700 hover:bg-indigo-100"
                >
                  View <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>


      </section>
    </div>

    <Footer />
  </React.Fragment>
}

export default Support
