import React from 'react'
import {API_BASE} from '../util'
import {get, post} from 'superagent'
import {buildQuery} from '../util'
import Spinner from './Spinner'

class UpgradeOption extends React.Component {
  state = {
    options: null
  }

  componentDidMount () {
    const {email} = this.props
    get(API_BASE + '/payment/precheck').query({email, upgrade: 1}).end((err, res) => {
      if (err) {
        alert(err.message)
        return
      }
      const {body} = res
      const options = body.options.map((option) => {
        return {
          title: body.productNameMap[option],
          price: body.productPriceMap[option],
          product: option
        }
      })
      this.setState({options})
    })
  }

  linkClick(product, title, price) {
    const {email} = this.props

    if (product === 'surge-ios-fus') {
      alert('Please login to the license management to renew the Feature Upgrade Subscription.');

      window.location.href = `/account?${buildQuery({email, type: "surge-ios"})}`;
    } else if (price === -1) {
      alert('Please contact support@nssurge.com to upgrade.');
    } else if (price === 0) {
      post(API_BASE + '/payment/zero-confirm').query({email, sku: product}).end((err, res) => {
        if (err) {
          alert(err.message)
          return
        }
        window.location.href = '/purchase-successful';
      });
    } else {
      window.location.href = `/payment?${buildQuery({email, title, product, price: price / 100})}`;
    }
  }

  renderOptionItem = ({ product, title, price }) => {
    return <li key={product} className="overflow-hidden rounded-md bg-white px-6 py-4 border">
      <button className="text-left" onClick={this.linkClick.bind(this, product, title, price)}>
        <p className="text-base font-medium leading-6 text-gray-900">{title}</p>
        <p className="mt-1 font-semibold text-gray-900">
          {price === -1 ? "Contact Support" : (price === 0 ? "Free" : ("$" + price / 100))}
        </p>
      </button>
    </li>
  }



  renderOption = () => {
    return <div>
      <ul className="space-y-3">
        {this.state.options.map(this.renderOptionItem)}
      </ul></div>
  }

  render() {
    if (!this.state.options) {
      return <Spinner />
    }

    return <div className="upgrade-options">
      {
        this.state.options.length
          ? this.renderOption()
          : <p className="mt-1 text-sm text-gray-500">
          No license upgrade option was found for {this.props.email}
          </p>
      }

    </div>
  }
}

export default UpgradeOption
