const root = document.getElementById('root')
export const API_BASE = root.dataset.endpoint || '/api'

export function parseQuery (queryString) {
  const query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  pairs.forEach((pair) => {
    var parts = pair.split('=')
    query[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1] || '')
  })
  return query
}

export function checkEmail (email) {
  if (email) {
    email = email.trim()
  }
  
  if (!email) {
    alert('Please enter your email address.')
    return
  }
  
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(email) ) {
    alert('Please input a valid email address.')
    return
  }

  return email
}

export function buildQuery (query) {
  let ret = ''
  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      if (ret) {
        ret += '&'
      }
      ret += key + '=' + encodeURIComponent(query[key])
    }
  }
  return ret
}

export function showAPIErrorAlert (error) {
  if (error) {
    alert(`Server is unavailable. Please try again later. (${error})`)
  } else {
    alert('Server is unavailable. Please try again later.');
  }
}

export function isChineseLanguage() {
  var lang = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

  return lang.startsWith("zh-") || lang === "zh";
}
