import './index.css'
import React from 'react'
import {post, get, del} from 'superagent'
import {API_BASE, showAPIErrorAlert} from '../util'
import { Dialog } from '@headlessui/react'
import Spinner from '../components/Spinner'


class TestFlight extends React.Component {
    state = {
        loading: true,
        registered: false,
        tvosJoined: false,
    }
    handleClose = () => {
        this.props.onClose();
    }

    join = () => {
        const c = window.confirm(`A TestFlight invitation will be sent to ${this.props.token.email}. Please check the junk mailbox if you do not receive.\nPlease note that this email is only used to receive invitation emails. The TestFlight binding email depends on the App Store account on the device that opens the invitation email.`);

        if (!c) return;

        this.setState({loading: true});
        post(API_BASE + '/account/testflight').send(this.props.token).end((err, res) => {
            if (err) {
              showAPIErrorAlert(err);
              this.props.onClose();      
              return
            }
            if (res.message) {
                alert(res.message);
                this.props.onClose();
                return
            }
            this.setState({
                loading: false,
                registered: true,
            });
        });

    }

    toggleTvos = () => {
        this.setState({ loading: true });
        post(API_BASE + '/account/testflight').send({
            email: this.props.token.email,
            key: this.props.token.key,
            'join-tvos': !this.state.tvosJoined
        }).end((err, res) => {
            if (err) {
                showAPIErrorAlert(err);
                this.props.onClose();
                return
            }
            if (res.message) {
                alert(res.message);
                this.props.onClose();
                return
            }

            this.setState({
                loading: false,
                tvosJoined: !this.state.tvosJoined,
            });
        });
    }


    optout = () => {
        const c = window.confirm(`Are you sure to remove yourself from TestFlight?`);

        if (!c) return;

        this.setState({loading: true});
        del(API_BASE + '/account/testflight').query(this.props.token).end((err, res) => {
            if (err) {
              showAPIErrorAlert(err);
              this.props.onClose();      
              return
            }
            if (res.message) {
                alert(res.message);
                this.props.onClose();
                return
            }
            this.setState({
                loading: false,
                registered: false,
            });
        });

    }


    componentDidMount() {
        this.setState({loading: true});
        get(API_BASE + '/account/testflight').query(this.props.token).end((err, res) => {
            if (err) {
              showAPIErrorAlert(err);
              this.props.onClose();      
              return
            }
            if (res.message) {
                alert(res.message);
                this.props.onClose();
                return
            }
            const {body} = res
            this.setState({loading: false,
                 registered: body.registered,
                 tvosJoined: body.tvosJoined});
        })
    }

    render() {
        return <div className="fixed inset-0 bg-black/30">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                    >
                        Surge iOS TestFlight Program
                    </Dialog.Title>

                    {this.state.loading ? <Spinner /> : (this.state.registered ? <React.Fragment>
                        <div className="mt-3">
                            <p className="text-sm text-gray-700">
                            If you want to migrate the TestFlight program access to another Apple ID, please opt-out first and then re-join.
                            </p>
                        </div>

                        <div className="mt-4 flex flex-row-reverse gap-2">
                            <button
                                type="button"
                                className="modal-btn-primary"
                                onClick={this.optout}
                            >
                                Opt-out
                            </button>

                            <button
                                type="button"
                                className="modal-btn-primary"
                                onClick={this.toggleTvos}
                            >
                                {this.state.tvosJoined ? 'Quit Surge tvOS Test Group' : 'Join Surge tvOS Test Group'}
                            </button>
                        </div>

                    </React.Fragment> : <React.Fragment>
                        <div className="mt-3 space-y-2">
                            <p className="text-sm text-gray-700">
                                You may join the TestFlight program to get the latest beta version of Surge iOS. The test version may be unstable and is recommended for testing purposes only.</p>
                                <p className="text-sm text-gray-700">
                                The invitation will be sent to {this.props.token.email}. You may accept it with another Apple ID.</p>
                                <p className="text-sm text-gray-700">Due to regional restrictions, Surge cannot be installed with a China region App Store account. Please make sure to accept the invitation using a non-China region App Store account.</p>

                        </div>

                        <div className="mt-4 flex flex-row-reverse gap-2">
                            <button
                                type="button"
                                className="modal-btn-primary"
                                onClick={this.join}
                            >
                                Join Now
                            </button>
                        </div>
                    </React.Fragment>)}

                    <div className="mt-6">
                        <p className="text-xs text-gray-500"><a href='https://kb.nssurge.com/surge-knowledge-base/faq/ios-testflight' target="_blank" rel="noreferrer">For questions related to TestFlight, please refer to TestFlight FAQ.</a>
                        </p>
                    </div>

                </Dialog.Panel>
            </div>
        </div>

    }
}

export default TestFlight
