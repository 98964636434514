import { useLocation, useNavigate, useParams } from "react-router";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} {...{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter;
