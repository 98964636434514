const {Stripe, location} = window

export function openStripe (stripeKey, sessionId) {
  var stripe = Stripe(stripeKey);

  stripe.redirectToCheckout({
    sessionId: sessionId
  }).then(function (result) {
    if (result.error) {
      alert(result.error.message)
      location.reload()
    }
  });
}

export function stripeForRenewFUS(stripeKey, stripeFUSPlan, email) {
  var stripe = Stripe(stripeKey);

  stripe.redirectToCheckout({
    items: [{
      plan: stripeFUSPlan,
      quantity: 1
    }],
    customerEmail: email,
    successUrl: location.protocol + '//' + location.host + '/purchase-successful?email=' + email,
    cancelUrl: location.protocol + '//' + location.host + '/account'
  }).then(function (result) {
    if (result.error) {
      alert(result.error.message)
      location.reload()
    }
  });
}