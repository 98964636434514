import './index.css'
import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { Link } from 'react-router-dom'
import { parseQuery, checkEmail } from '../util'
import UpgradeOption from '../components/UpgradeOption'
import { isChineseLanguage } from '../util'

import { CheckIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

const tiers = [
  {
    id: 'surge-mac-1',
    name: '1 Device',
    fullName: 'Surge Mac 5 License for 1 Device',
    href: '#',
    price: 49.99,
    features: [
      'Lifetime license',
      'For Single Mac device'
    ]
  },
  {
    id: 'surge-mac-3',
    name: '3 Devices',
    fullName: 'Surge Mac 5 License for 3 Devices',
    href: '#',
    price: 69.99,
    features: [
      'Lifetime license',
      'For 3 Mac devices',
      '53% off compared to one device license'
    ]
  },
  {
    id: 'surge-mac-5',
    name: '5 Devices',
    fullName: 'Surge Mac 5 License for 5 Devices',
    href: '#',
    price: 99.99,
    features: [
      'Lifetime license',
      'For 5 Mac devices',
      '60% off compared to one device license'
    ]
  }
]

const faqs = [
  {
    question: 'What does "Lifetime license" mean exactly?',
    answer:
      "Purchase Surge and use it forever. Major upgrades may come at an additional upgrade cost.",
  },
  {
    question: 'What does "Device Limitation" mean exactly?',
    answer: 'You can only use Surge on the license number of devices. But you can always deactivate the license on a device and transfer to another device. If you forget to deactivate the license and you lost control of the activated device. You may reset your license to deactivate all devices. But you can only perform reset every 7 days.',
  },
  {
    question: 'What does "Personal License" mean?',
    answer:
      "Surge iOS license is a personal license. You can only use it on your devices, which you own or control. You may not share your license with others.",
  },
  {
    question: 'Can I get future Surge Mac updates for free?',
    answer:
      "Surge Mac uses a paid update system for major releases, which means that all updates are free until a major release is available.\nWe usually do major version upgrades every three years. Don't worry, if you make a purchase six months before a major update, you can upgrade to the latest version for free. If you go beyond this period, you can still make a purchase of the update at a reduced price.",
  },
  {
    question: 'Can I get future Surge iOS updates for free?',
    answer:
      "Surge iOS uses a subscription update system, which gives you all new feature updates for free for one year after purchase. After the expiration date, you will need to renew your subscription to unlock subsequent new features. Unlocked features are available for lifetime, and maintenance updates are available for lifetime.",
    url: isChineseLanguage() ? 'https://nssurge.zendesk.com/hc/zh-cn/articles/360025271874' : 'https://nssurge-english.zendesk.com/hc/en-us/articles/360025426913'

  },

  {
    question: 'Does the purchase include technical support?',
    answer:
      'Please note that Surge products are designed for professional users and do not include technical support. Our customer service only handles billing related issues.',
  },

]


function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}


class BuyNow extends React.Component {
  state = {
    email: '',
    showUpgradeDialog: false,
    upgradeOptionLoaded: false,
    showSurgeMac: true
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  componentDidMount() {
    const query = parseQuery(window.location.search)
    if (query.upgrade_email) {
      this.setState({ showUpgradeDialog: true, email: query.upgrade_email }, () => {
        this.viewUpgradeOptions()
      })
    } else if (query.upgrade) {
      this.setState({ showUpgradeDialog: true });
    }

    if (iOS()) {
      this.setState({ showSurgeMac: false });
    }
  }

  viewUpgradeOptions = () => {
    var email = checkEmail(this.state.email)
    if (!email) {
      return false;
    }

    this.setState({ upgradeOptionLoaded: true, showUpgradeDialog: true })
  }


  closeModal = () => {
    this.setState({ showUpgradeDialog: false })
  }

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.viewUpgradeOptions();
    }
  }

  renderUpgradeInputDialog() {
    return <React.Fragment>
      <Dialog.Description className="text-sm text-gray-500">
        Enter Your License Email to Check Upgrade Options
      </Dialog.Description>
      <div className="my-4">
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
          value={this.state.email} onChange={this.handleInputChange} onKeyDown={this.onKeyDown}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      <div className="mt-4 flex flex-row-reverse gap-2">
        <button
          type="button"
          className="modal-btn-primary"
          onClick={this.viewUpgradeOptions}
        >
          Continue
        </button>
        <button
          type="button"
          className="modal-btn-cancel"
          onClick={this.closeModal}
        >
          Cancel
        </button>

      </div>
    </React.Fragment>
  }

  renderUpgradeOptionDialog() {
    return <React.Fragment>
      <div className="my-4">
        <UpgradeOption
          email={this.state.email}
        />
      </div>
      <div className="mt-4 flex flex-row-reverse gap-2">

        <button
          type="button"
          className="modal-btn-cancel"
          onClick={this.closeModal}
        >
          Close
        </button>

      </div>

    </React.Fragment>
  }



  render() {
    return <React.Fragment>
      <Header />
      <section>
        <div className="bg-gray-900">
          {this.state.showSurgeMac && <div className="relative overflow-hidden pt-32 pb-96 lg:pt-40">
            <div>
              <img
                className="absolute bottom-0 left-1/2 w-[1440px] max-w-none -translate-x-1/2"
                src="https://tailwindui.com/img/component-images/grid-blur-purple-on-black.jpg"
                alt=""
              />
            </div>
            <div className="relative mx-auto max-w-7xl px-6 text-center lg:px-8">
              <div className="mx-auto max-w-2xl lg:max-w-4xl">
                <h2 className="text-lg font-semibold leading-8 text-indigo-400">Pricing</h2>
                <p className="mt-2 text-4xl font-bold tracking-tight text-white">
                  Choose your package
                </p>
              </div>
            </div>
          </div>}
          <div className="flow-root bg-white">
            {this.state.showSurgeMac && <div className="relative -mt-80">
              <div className="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-5xl lg:grid-cols-3 lg:gap-8">
                  {tiers.map((tier) => (
                    <div key={tier.name} className="flex flex-col rounded-3xl bg-white shadow-xl ring-1 ring-black/10">
                      <div className="p-8 sm:p-10">
                        <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600" id={tier.id}>
                          {tier.name}
                        </h3>
                        <div className="mt-4 flex items-baseline text-5xl font-bold tracking-tight text-gray-900">
                          ${tier.price}
                        </div>
                        <p className="mt-6 text-base leading-7 text-gray-600">{tier.fullName}</p>
                      </div>
                      <div className="flex flex-1 flex-col p-2">
                        <div className="flex flex-1 flex-col justify-between rounded-2xl bg-gray-50 p-6 sm:p-8">
                          <ul className="space-y-6">
                            {tier.features.map((feature) => (
                              <li key={feature} className="flex items-start">
                                <div className="flex-shrink-0">
                                  <CheckIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                                </div>
                                <p className="ml-3 text-sm leading-6 text-gray-600">{feature}</p>
                              </li>
                            ))}
                          </ul>
                          <div className="mt-8">
                            <Link
                              to={'/payment?product=' + encodeURIComponent(tier.id) + '&title=' + encodeURIComponent(`${tier.fullName}`) + '&price=' + tier.price}
                              className="inline-block w-full rounded-lg bg-indigo-600 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-white shadow-md hover:bg-indigo-700"
                              aria-describedby={tier.id}
                            >
                              Buy Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>}
            <div className="relative mx-auto mt-16 max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-md lg:max-w-5xl">
                <div className="flex flex-col gap-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 lg:flex-row lg:items-center lg:gap-8">
                  <div className="lg:min-w-0 lg:flex-1">
                    <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Surge iOS Pro Personal License</h3>
                    <div className="mt-2 text-base leading-7 text-gray-600">
                      Up to 3 iOS devices for <span className="font-semibold text-gray-900">$49.99</span>. And you may upgrade to 6 devices for free if you bind the license to your iCloud account.
                    </div>
                  </div>
                  <div>
                    <Link
                      to={'/payment?product=' + 'surge-ios-3-pro' + '&title=' + 'Surge iOS Pro Personal License' + '&price=' + '49.99'}
                      className="inline-block rounded-lg bg-indigo-50 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-indigo-700 hover:bg-indigo-100"
                    >
                      Buy Surge iOS <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {!this.state.showSurgeMac &&
              <div className="relative mx-auto mt-8 max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-md lg:max-w-5xl">
                  <div className="flex flex-col gap-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 lg:flex-row lg:items-center lg:gap-8">
                    <div className="lg:min-w-0 lg:flex-1">
                      <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Surge Mac License</h3>
                      <div className="mt-2 text-base leading-7 text-gray-600">
                        Surge Mac is for Mac devices, such as MacBook, Mac mini and iMac.
                      </div>
                    </div>
                    <div>
                      <button
                        className="inline-block rounded-lg bg-indigo-50 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-indigo-700 hover:bg-indigo-100"
                        onClick={
                          (e) => {
                            this.setState({ showSurgeMac: true })
                          }
                        }
                      >
                        Buy Surge Mac <span aria-hidden="true">&rarr;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="relative mx-auto mt-8 max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-md lg:max-w-5xl">
                <div className="flex flex-col gap-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 lg:flex-row lg:items-center lg:gap-8">
                  <div className="lg:min-w-0 lg:flex-1">
                    <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Existing Users</h3>
                    <div className="mt-2 text-base leading-7 text-gray-600">
                      If you already have a license, input your license email to check the discounted upgrade price.
                    </div>
                  </div>
                  <div>
                    <a
                      href="#show-upgrade-option"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          this.setState({ upgradeOptionLoaded: false, showUpgradeDialog: true })
                        }
                      }
                      className="inline-block rounded-lg bg-indigo-50 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-indigo-700 hover:bg-indigo-100"
                    >
                      See Upgrade Options <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>


            {/* FAQ offset */}
            <div className="mx-auto mb-20 max-w-6xl pt-16 px-4 sm:px-6 lg:pt-20 lg:px-8">
              <div className="lg:grid lg:grid-cols-4 lg:gap-8">
                <div className="space-y-4">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">Frequently asked questions</h2>
                </div>
                <div className="mt-12 lg:col-span-3 lg:mt-0">
                  <dl className="space-y-12">
                    {faqs.map((faq, i) => (
                      <div key={i}>
                        <dt className="text-lg font-medium leading-6 text-gray-900">{faq.question}</dt>
                        <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                        {faq.url && <dd className="mt-2"><a href={faq.url} target="_blank" rel="noreferrer" className="font-medium text-indigo-600 hover:text-indigo-500">Read More…</a></dd>}
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Transition appear show={this.state.showUpgradeDialog} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 bg-black/30">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Upgrade Options
                  </Dialog.Title>


                  {this.state.upgradeOptionLoaded ? this.renderUpgradeOptionDialog() : this.renderUpgradeInputDialog()}

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>




      <Footer />
    </React.Fragment>
  }
}


export default BuyNow
