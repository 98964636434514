import './index.css'
import React from 'react'
import { post } from 'superagent'
import { API_BASE, checkEmail, showAPIErrorAlert } from '../util'
import Header from '../Header'
import Footer from '../Footer'

class Refund extends React.Component {
	state = {
		type: 'surge-mac',
		email: '',
		key: '',
		receiptID: '',
		reason: '',
		loading: false,
	}

	componentDidMount() {
	}

	request = (e) => {
		const email = checkEmail(this.state.email)
		if (!email) {
			return false
		}

		const key = this.state.key.trim()
		if (!key) {
			alert('Please enter your key.')
			return false
		}

		let receiptID = this.state.receiptID.trim()

		if (receiptID.length !== 8 && receiptID.length !== 9) {
			alert('Please enter your receipt ID. You may find it in the receipt email, which looks like #1234-5678.')
			return false
		}

		receiptID = '#' + receiptID;

		const reason = this.state.reason.trim()
		if (!reason) {
			alert('Please input the refund reason.')
			return false
		}

		const type = this.state.type

		this.setState({ loading: true })

		post(API_BASE + '/payment/refund').send({
			email, key, type, receiptID, reason
		}).end((err, res) => {
			this.setState({ loading: false })

			if (err) {
				showAPIErrorAlert(err)
				return
			}
			const { body } = res

			alert(body.message)
		})
		return false
	}

	handleInputChange = (event) => {
		const target = event.target
		const value = target.value
		const name = target.name

		this.setState({
			[name]: value
		})
	}

	typeDidChange = (event) => {
		this.setState({ type: event.target.value })
	}



	render() {
		return <React.Fragment>
			<Header />
			<div className="mt-10 sm:mt-0 bg-gray-100 py-20">
				<div className="md:grid md:grid-cols-5 md:gap-6 max-w-5xl mx-auto">
					<div className="md:col-span-2">
						<div className="px-4 sm:px-0">
							<h3 className="text-xl font-medium leading-6 text-gray-900">Request a Refund</h3>
							<div className="mt-3 leading-6 text-sm text-gray-600">
								<p>Please notice:</p>
								<ul className="list-outside list-disc">
									<li>We only accept a refund application within 30 days since purchasing. It may take 7 days to process the refund.</li>
									<li>You must deactivate all the devices first.</li>
									<li>If you have already applied for a refund from us within a year, further refund requests will be denied. Same email address and payment method are all treated as a same person.</li>
									<li>We may deny your request if we find any suspicious actions, such as activating and deactivating numerous devices repeatedly.</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="mt-5 md:col-span-3 md:mt-0">
						<form onSubmit={(e) => {
							e.preventDefault();
							this.request();
						}}>
							<div className="overflow-hidden shadow sm:rounded-md">
								<div className="bg-white px-4 py-5 sm:p-6">
									<div className="grid grid-cols-6 gap-6">

										<div className="col-span-6 sm:col-span-3">
											<label className="block text-sm font-medium text-gray-700">
												Product Type
											</label>
											<select
												id="type"
												name="type"
												className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
												onChange={this.typeDidChange}
												value={this.state.type}
											>
												<option value="surge-mac">Surge Mac</option>
												<option value="surge-ios">Surge iOS</option>
												<option value="surge-ios-fus">Surge iOS Feature Subscription</option>
											</select>
										</div>


										<div className="grid grid-cols-3 gap-6 col-span-4">
											<div className="col-span-3">
												<label className="block text-sm font-medium text-gray-700">
													Receipt ID
												</label>
												<div className="mt-1 flex rounded-md shadow-sm">
													<span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
														#
													</span>
													<input
														type="text"
														name="receiptID"
														id="receiptID"
														value={this.state.receiptID} onChange={this.handleInputChange}
														className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
														placeholder="1234-5678"
													/>
												</div>
												<p className="mt-2 text-sm text-gray-500">
												You may find it in the receipt email.
											</p>

											</div>
										</div>

										<div className="col-span-6">
											<label className="block text-sm font-medium text-gray-700">
												License email address
											</label>
											<input
												type="text"
												name="email"
												id="email"
												autoComplete="email"
												value={this.state.email} onChange={this.handleInputChange}
												className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											/>
										</div>


										<div className="col-span-6">
											<label className="block text-sm font-medium text-gray-700">
												License Key
											</label>
											<input
												type="text"
												name="key"
												id="key"
												value={this.state.key} onChange={this.handleInputChange}
												className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											/>
																						<p className="mt-2 text-sm text-gray-500">
																						You may find it in the license email.
											</p>

										</div>

										<div className="col-span-6">
											<label htmlFor="about" className="block text-sm font-medium text-gray-700">
												Reason
											</label>
											<div className="mt-1">
												<textarea
													id="reason"
													name="reason"
													rows={3}
													value={this.state.reason} onChange={this.handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
												/>
											</div>
										</div>


									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
									<button
										type="submit"
										disabled={this.state.loading}
										className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
									>
										Submit
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Footer hideSeparator={true} />

		</React.Fragment>
	}

}
// 	return <React.Fragment>
// 		<Header />
// 		<div className="redeem modal">
// 			<h1></h1>
// 			<div className="description" style={{ width: 700 }}>
// 			</div>
// 			<div className="modal-wrap">
// 				<form className="login">
// 					<div className="form-row form-row--radio">
// 						<label className="block">Select Product</label>
// 						<RadioGroup
// 							onChange={this.typeDidChange}
// 							selectedValue={this.state.type}
// 							inline={true}
// 						>
// 							<Radio label="Surge Mac" value="surge-mac" name="type" />
// 							<Radio label="Surge iOS" value="surge-ios" name="type" />
// 							<Radio label="Surge iOS Feature Subscription" value="surge-ios-fus" name="type" />
// 						</RadioGroup>

// 					</div>
// 					<div className="form-row">
// 						<label className="block" htmlFor="email">License Email</label>
// 						<div className="bp3-input-group bp3-large" style={{ margin: 20 }}>
// 							<input id="email" className="bp3-input" placeholder="License Email" type="text" name="email" value={this.state.email} onChange={this.handleInputChange} />
// 						</div>
// 					</div>
// 					<div className="form-row">
// 						<label className="block" htmlFor="key">License Key</label>
// 						<label className="description">You may find it in the license email.</label>

// 						<div className="bp3-input-group bp3-large" style={{ margin: 20 }}>
// 							<input id="key" className="bp3-input" placeholder="License Key" type="text" name="key" value={this.state.key} onChange={this.handleInputChange} />
// 						</div>
// 					</div>
// 					<div className="form-row">
// 						<label className="block">Receipt ID</label>
// 						<label className="description">You may find it in the receipt email, which looks like #1234-5678</label>
// 						<div className="bp3-input-group bp3-large" style={{ margin: 20 }}>
// 							<input id="receiptID" className="bp3-input" placeholder="#1234-5678" type="text" name="receiptID" value={this.state.receiptID} onChange={this.handleInputChange} />
// 						</div>
// 					</div>
// 					<div className="form-row">
// 						<label className="block">Reasons for refund</label>
// 						<div className="bp3-input-group" style={{ margin: 20 }}>
// 							<TextArea id="reason" class="bp3-input" style={{ height: 100 }} type="text" name="reason" value={this.state.reason} onChange={this.handleInputChange} />
// 						</div>
// 					</div>

// 					<div className="form-row" style={{ textAlign: 'center' }}>
// 						{this.getButton("Submit", this.request, { style: { width: 150 }, intent: "primary" })}
// 					</div>
// 				</form>
// 			</div>
// 		</div>
// 		<Footer />

// 	</React.Fragment>
// }


export default Refund
