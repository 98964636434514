import './index.css'
import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import Constants from '../Constants'
import { Link } from "react-router-dom";

import {
  ArrowPathIcon,
  CogIcon,
  ShieldCheckIcon,
  BoltIcon,
  DevicePhoneMobileIcon,
  NoSymbolIcon,
  CpuChipIcon,
  ShareIcon,
  SignalIcon
} from '@heroicons/react/24/outline'

class Home extends React.Component {
  componentDidMount() {
  }

  features() {
    const features = [
      {
        name: 'High Performance & Stability',
        description: 'With enterprise-grade stability, Surge is designed to run uninterruptedly at high performance with minimum system resource consumption. Surge handles all the heavy-lifting on your network and leaves you worry-free.',
        icon: BoltIcon,
      },
      {
        name: 'Flexible Rule System',
        description: 'You can set up proxy forwarding rules based on domain, IP, GeoIP, Process Name, URL, etc. Surge will forward all requests based on the rules accordingly.',
        icon: ShareIcon,
      },
      {
        name: 'Integration with iOS Version',
        description: 'Dashboard can connect to Surge iOS through Wi-Fi or USB, monitor and analyze network requests on the iOS devices. You can even examine cellular network requests through USB.',
        icon: DevicePhoneMobileIcon,
      },
      {
        name: 'HTTPS Decryption',
        description: 'Decrypt HTTPS traffic by MitM. Certificate generator will help you generate A certificate for debugging and make the certificate trusted by the system.',
        icon: ShieldCheckIcon,
      },
      {
        name: 'Local DNS Mapping',
        description: 'Surge supports local-customized DNS mapping and has multiple functional modules, including wildcard, alias and custom DNS server.',
        icon: CogIcon,
      },
      {
        name: 'Metered Network Mode',
        description: 'Control which processes are allowed to access the Internet. Useful when using a metered connection like a mobile data hotspot.',
        icon: NoSymbolIcon,
      },
      {
        name: 'Enhanced Mode',
        description: 'Surge may set up a virtual network interface to handle all network traffics, regardless of the app supports proxy or not.',
        icon: CpuChipIcon,
      },
      {
        name: 'Gateway Mode',
        description: 'You can even use Surge Mac as a Layer 3 gateway to handle traffics from other devices.',
        icon: SignalIcon,
      },
      {
        name: 'Up to Date',
        description: 'Surge Mac is always ready for the latest operation system, like macOS Ventura.',
        icon: ArrowPathIcon,
      },
    ]

    return (
      <div className="relative bg-white pt-8">
        <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
          <h2 className="text-lg font-semibold text-indigo-600">Designed for Power User</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Control your network
          </p>
          <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
            Surge can takeover your network traffic, then redirect or modify it as your wish.
          </p>
          <div className="mt-20">
            <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                          <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base leading-7 text-gray-600">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return <React.Fragment>
      <Header />

      <section className="preview">
        <h1 className="slogan">Advanced Network Toolbox for Mac &amp; iOS</h1>
        <img srcSet={require("./screenshot.webp") + " 2x"} alt="Screenshot of Surge for Mac" />
      </section>
      <section className="introduction">
        <div className="content">
          <h2>Meets All Your Personalization about Network</h2>
          <img srcSet={require("./screenshot-dashboard.webp") + " 2x"} alt="Screenshot of Dashboard" class="object-contain max-w-full max-h-full" />
          <p className="text-gray-500 mt-2">Surge is a <strong>network toolbox</strong> for power users and a high-performance <strong>HTTP/SOCKS5 proxy server</strong>. It is capable
            of intercepting and logging the summary of network traffics, and meanwhile transmitting the traffic to other proxy
            servers according to the flexible rule system. Multiple proxy protocols are supported including HTTP, HTTPS, SOCKS5,
            SOCK5 over TLS.</p>
          <p>Launching the Dashboard, Surge then becomes a sophisticated <strong>HTTP debugging tool</strong>. You can monitor all recent network requests, adjust proxy rules, rewrite requests and manipulate local DNS mapping.</p>
          <div className="my-6">
            <div className="inline-flex rounded-md shadow mr-3">
              <a
                href={Constants.surgeMacDownloadURL}
                className="w-48 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
              >
                Download v5
              </a>
            </div>
            <div className="inline-flex mt-3">
              <Link
                to="/buy_now"
                className="w-48 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-5 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200"
              >
                Buy Now
              </Link>
            </div>

            <div className="mt-2">
              <span
                className="inline-flex items-center justify-center py-1 text-sm font-medium text-gray-500 "
              >
                Full-featured 7-day free trial, no credit card required.
              </span>
            </div>

            <div class="mt-4">
              <a href="/go/en/mac-legacy-versions" class="text-sm font-semibold leading-6 text-gray-900">Download v2/v3/v4 <span aria-hidden="true">→</span></a>
            </div>


          </div>

        </div>
      </section>
      <section className="my-0 py-0">
        <div>{this.features()}</div>
      </section>

      <section className="my-20">
        <div className="relative">
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg bg-gradient-to-r from-orange-400 to-pink-500 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
              <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                <div className="lg:self-center">
                  <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    <span className="block">Fancy the iOS version?</span>
                    <span className="block text-red-900 text-xl mt-2">Surge iOS provides equally powerful functionality as the Mac version does.</span>
                  </h2>
                  <div className="ml-4 mt-4 text-sm leading-6 text-white">
                    <ul className="list-disc">
                      <li>All features can be independently utilized without relying on the Mac version</li>
                      <li>It is capable of overriding the system DNS settings even on Cellular network</li>
                      <li>SOCKS5 and HTTPS proxy can be used on iOS even if they are not supported by system</li>
                    </ul>
                  </div>
                  <a href="https://itunes.apple.com/us/app/surge-3/id1442620678?ls=1&mt=8" className="app-store"></a>
                </div>
              </div>
              <div className="-mt-6">
                <img
                  className="translate-y-6 lg:translate-x-8 transform object-cover object-left-top lg:translate-y-20 wi"
                  srcSet={require('./iphone-preview.webp') + " 2x"}
                  alt="App screenshot"
                />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="saying">
        <div className="mac-icon"></div>
        <h2>See What Our Users Are Saying</h2>
        <div className="row remarks">
          <div className="col s12 m12 l4">
            <a href="https://leancloud.cn/"><img src="./img/avatar-hjiang.jpg" alt="" /></a>
            <p className="content">We use Surge to help us simulate various network conditions and debug issues in our SDK. We also recommend it to our users when they have trouble figuring out network-related issues.</p>
            <p className="title">Hong Jiang - CEO of LeanCloud</p>
          </div>
          <div className="col s12 m12 l4">
            <a href="https://www.growingio.com/"><img src="./img/avatar-dingding.jpg" alt="" /></a>
            <p className="content">Surge is so awesome and easy to use. It saves us much time to debug the SDK issues among different browsers and mobile devices. It has been one of the development
              tools we can't live without.</p>
            <p className="title">Dingding Ye - CTO of GrowingIO</p>
          </div>
          <div className="col s12 m12 l4">
            <img src="./img/avatar-andy.jpg" alt="" />
            <p className="content">Surge is so versatile that I use it to reverse engineer customized protocols as well as improving network connectivity. It is no exaggeration to say that Surge is life-changing.</p>
            <p className="title">Andy Ren - Engineer at Airbnb</p>
          </div>
        </div>
      </section>

      <Footer hideSeparator={true} />
    </React.Fragment>
  }
}

export default Home
