import { useEffect } from 'react'
import * as qs from 'query-string';
import { useLocation } from "react-router-dom";

const DeviceActivate = () => {
  const search = useLocation().search;

  useEffect(() => {
    const query = qs.parse(search, { ignoreQueryPrefix: true });

    if (query["email"] && query["key"]) {
      window.location.href = `surge:///email-license?email=${query["email"]}&key=${query["key"]}`;
    } else {
      window.location.href = 'surge:///email-license';
    }

  }, [search]);

  return null;
}

export default DeviceActivate
